import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import '../../styles/curryproducts.css'

export default function CurryHomepage() {
    return (
        <>
            <div className="CurryFirstLayer">
                <Container>
                    <Row>
                        <Col lg="6">
                            <Row>
                                <Col lg="12" className="CurryFirstLayer-Title">
                                    <h2>Curry by Maker Recipe</h2>
                                </Col>
                                {/* <Col lg="12" className="CurryFirstLayer-Subtitle">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed 
                                        do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                                        Ut enim ad minim veniam, quis nostrud exercitation ullamco 
                                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute 
                                        irure dolor in reprehenderit in voluptate velit esse cillum 
                                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat 
                                        cupidatat non proident, sunt in culpa qui officia deserunt 
                                        mollit anim id est laborum.
                                    </p>
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </Container>
             </div>
             <div className="CurrySecondLayer-curry">
                <Container>
                    <Row>
                        <Col lg="6">
                            {/* <Link to="/product/fish-curry-powder"> */}
                                <div className="zoom-curryProduct">
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                    src="../../images/curryProducts/Ikan_25gm.png" 
                                                    alt="Curry Product 1"
                                                    width={500}
                                                    height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="curryProductName">
                                            <p>Fish Curry Powder</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="curryProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                        <Col lg="6">
                            {/* <Link to="/product/chicken-meat-curry-powder"> */}
                                <div className="zoom-curryProduct">
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                    src="../../images/curryProducts/Kari_Daging_25gm.png" 
                                                    alt="Curry Product 1"
                                                    width={500}
                                                    height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="curryProductName">
                                            <p>Chicken & Meat Curry Powder</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="curryProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                    </Row>

                    {/* Second row of curry products */}
                    <Row>
                        <Col lg="6">
                            {/* <Link to="/product/shrimp-crab-dried-fish-curry-powder"> */}
                                <div className="zoom-curryProduct">
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                    src="../../images/curryProducts/Masak_Lemak_25gm.png" 
                                                    alt="Curry Product 3"
                                                    width={500}
                                                    height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="curryProductName">
                                            <p>Shrimp, Crab & Dried Fish Curry Powder</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="curryProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                        <Col lg="6">
                            {/* <Link to="/product/red-curry-powder"> */}
                                <div className="zoom-curryProduct">
                                    <Row>
                                        <Col md="12">
                                            <StaticImage 
                                                    src="../../images/curryProducts/Masak_Merah_25gm.png" 
                                                    alt="Curry Product 4"
                                                    width={500}
                                                    height={500} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12" className="curryProductName">
                                            <p>Red Curry Powder</p>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="12" className="curryProductDetails">
                                            <p>View Details..</p>
                                        </Col>
                                    </Row> */}
                                </div>
                            {/* </Link> */}
                        </Col>
                    </Row>
                </Container>
             </div>
        </>
        
    )
}

