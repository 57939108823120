import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import CurryHomepage from "../../components/products-curry/CurryHomepage";

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          {/* For page head */}
          <Helmet>
                <title>Maker Recipe Sdn. Bhd.-Curry</title>
            </Helmet>

          <CurryHomepage />
        </div>
      </section>
    </Layout>
    
  )
}